import { useState } from 'react';

const defaultOptions = {
  target: 40,
};

const useScrollPosition = (options = {}) => {
  const { target } = { ...defaultOptions, ...options };
  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);

  window.addEventListener('scroll', () => {
    setScrollPosition(window.pageYOffset);
  });

  return scrollPosition > target;
};

export default useScrollPosition;
