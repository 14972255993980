import { registrationStatuses } from '../constants';
import { registrationRoot } from '../constants/navigation';

const registrationRedirect = (history, user, path) => {
  if (user.registrationStatus !== registrationStatuses.COMPLETE
    && user.registrationStatus !== registrationStatuses.GC_COMPLETE) {
    switch (user.registrationStatus) {
      case registrationStatuses.REGISTERED: {
        if (path !== `${registrationRoot}/1`) { history.push(`${registrationRoot}/1`); }
        break;
      }
      case registrationStatuses.VERIFIED_USER_INFO: {
        if (path !== `${registrationRoot}/2`) { history.push(`${registrationRoot}/2`); }
        break;
      }
      case registrationStatuses.VERIFIED_COMPANY: {
        if (path !== `${registrationRoot}/3`) { history.push(`${registrationRoot}/3`); }
        break;
      }
      case registrationStatuses.UPLOADED_DOCS: {
        if (path !== `${registrationRoot}/4`) { history.push(`${registrationRoot}/4`); }
        break;
      }
      case registrationStatuses.VERIFIED_PROJECT: {
        if (path !== `${registrationRoot}/3`) { history.push(`${registrationRoot}/3`); }
        break;
      }
      default: break;
    }
  } else if ((path === `${registrationRoot}/3` && user.registrationStatus === registrationStatuses.COMPLETE)
    || (path === `${registrationRoot}/3` && user.registrationStatus === registrationStatuses.GC_COMPLETE)) {
    history.push('/');
  } else if (path !== `${registrationRoot}/4` && path.startsWith(registrationRoot)) {
    history.push('/');
  }
};

export default registrationRedirect;
