import axios from 'axios';
import { companyTypes } from '../constants';

export const subContractorsSelector = (state) => state.subContractors;

const GET_SUB_CONTRACTORS_REQUEST = 'GET_SUB_CONTRACTORS_REQUEST';
const GET_SUB_CONTRACTORS_OK = 'GET_SUB_CONTRACTORS_OK';
const GET_SUB_CONTRACTORS_ERROR = 'GET_SUB_CONTRACTORS_ERROR';
const GET_SUB_CONTRACTOR_COMPANY = 'GET_SUB_CONTRACTOR_COMPANY';

export const getSubContractorsRequest = () => ({
  type: GET_SUB_CONTRACTORS_REQUEST,
});
export const getSubContractorsOk = (payload) => ({
  type: GET_SUB_CONTRACTORS_OK,
  payload,
});
export const getSubContractorsError = (error) => ({
  type: GET_SUB_CONTRACTORS_ERROR,
  error,
});

export const getSubContractorCompany = (payload) => ({
  type: GET_SUB_CONTRACTOR_COMPANY,
  payload,
});

export const getSubContractorsThunk = (isEpp) => (dispatch) => {
  dispatch(getSubContractorsRequest());

  const isEppCompany = Boolean(isEpp);

  return axios
    .get('/api/company/getLightAll', {
      params: {
        companyType: companyTypes.SUB_CONTRACTOR,
        isEppCompany,
      },
    })
    .then(({ data }) => {
      dispatch(getSubContractorsOk(data));
    })
    .catch(() => {
      dispatch(getSubContractorsError('Failed to fetch companies.'));
    });
};

export const getSubContractorsGc = (gcId) => (dispatch) => {
  dispatch(getSubContractorsRequest());
  const queryParams = [];
  queryParams.push(`generalContractorId=${gcId}`);
  queryParams.push(`companyType=${companyTypes.SUB_CONTRACTOR}`);

  return axios
    .get(`/api/company?${queryParams.join('&')}`)
    .then(({ data }) => {
      dispatch(getSubContractorsOk(data));
    })
    .catch(() => {
      dispatch(getSubContractorsError('Failed to fetch companies.'));
    });
};
export const initialState = {
  loading: false,
  loaded: false,
  subContractors: [],
  error: null,
  company: {},
};

const subContractors = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUB_CONTRACTORS_REQUEST:
      return { ...initialState, loading: true };
    case GET_SUB_CONTRACTORS_OK:
      return { ...initialState, loaded: true, subContractors: action.payload };
    case GET_SUB_CONTRACTORS_ERROR:
      return { ...initialState, error: action.error };
    case GET_SUB_CONTRACTOR_COMPANY:
      return { ...initialState, company: action.payload };
    default:
      return state;
  }
};

export default subContractors;
