import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { CssBaseline } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import CssGlobal from './global-css';
import reducers from './ducks/reducers';
import * as serviceWorker from './serviceWorker';
import { EventEmitter } from './contexts/EventContext';
import { GlobalsProvider } from './contexts/GlobalsContext';
import { KeycloakProvider } from './contexts/KeycloakContext';
import { UserProvider } from './contexts/UserContext';
import theme from './theme';
import { setGtagCookie } from './utils/analytics';
import './theme.css';
import './index.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

setGtagCookie();

const store = createStore(reducers, composeWithDevTools(
  applyMiddleware(
    thunk,
  ),
));

ReactDOM.render(
  <ReduxProvider store={store}>
    <ThemeProvider theme={createTheme(theme)}>
      <CssBaseline />
      <CssGlobal />
      <Router>
        <EventEmitter>
          <GlobalsProvider>
            <KeycloakProvider>
              <UserProvider>
                <App />
              </UserProvider>
            </KeycloakProvider>
          </GlobalsProvider>
        </EventEmitter>
      </Router>
    </ThemeProvider>
  </ReduxProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
