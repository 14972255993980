import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  makeStyles,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useLoan from '../api/getLoan';
import AppBar from '../components/Navigation/AppBar';
import Drawer from '../components/Navigation/Drawer';
import { Footer } from '../components/Navigation/Footer';
import { useUser } from '../contexts/UserContext';
import { loanStatuses, companyTypes, program } from '../constants';
import { getSubContractorCompany } from '../ducks/SubContractors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',
  },
  mainContent: {
    flex: '1 0 auto',
  },
  topPadding: theme.mixins.toolbar,
}));

const AppBarDrawer = ({
  children,
  hasDrawer,
  links,
  defaultPath,
  hideLogo,
}) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const { user } = useUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    loan,
    getActiveLoan,
  } = useLoan();
  function enableDrawer() {
    return (loan.status === loanStatuses.SERVICING || user.companyType === companyTypes.STRUXTION || user.companyType === companyTypes.GENERAL_CONTRACTOR || user.companyType === companyTypes.SUB_CONTRACTOR);
  }
  React.useEffect(() => {
    if (user.companyId && user.companyType !== companyTypes.STRUXTION && user.companyProgramType !== program.x_pay && user.companyProgramType !== program.x_pay_plus && user.companyType !== companyTypes.GENERAL_CONTRACTOR && !location.pathname.includes('registration')) {
      getActiveLoan(user.companyId);
    }
  }, [getActiveLoan, user, location]);

  React.useEffect(() => {
    if (Object.keys(loan).length > 0) dispatch(getSubContractorCompany(loan.company));
  }, [dispatch, loan]);

  return (
    <>
      <div className={classes.root}>
        <AppBar hideLogo={hideLogo} toggleDrawer={toggleDrawer} defaultPath={defaultPath} hasDrawer={hasDrawer} user={user} />
        {hasDrawer && enableDrawer() && <Drawer toggleDrawer={toggleDrawer} defaultPath={defaultPath} drawerOpen={drawerOpen} links={links} user={user} />}
        <main className={classes.main}>
          <Box mt={2} className={classes.mainContent}>
            <Box className={classes.topPadding} mb={4} />
            <Container>
              <Box display="flex" flexDirection="column">
                {children}
              </Box>
            </Container>
          </Box>
          <Footer />
        </main>
      </div>
    </>
  );
};

AppBarDrawer.defaultProps = {
  hasDrawer: false,
  links: [],
  defaultPath: '/',
  hideLogo: true,
};

AppBarDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  hasDrawer: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    icon: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
    to: PropTypes.string,
  })),
  defaultPath: PropTypes.string,
  hideLogo: PropTypes.bool,
};

export const AppBarWithoutDrawer = (props) => (<AppBarDrawer {...props} />);
export const AppBarWithDrawer = (props) => (<AppBarDrawer hasDrawer {...props} />);
