import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: (props) => props?.padding || theme.spacing(5),
    margin: (props) => props?.margin || `${theme.spacing(2.5)}px 0`,
    borderRadius: 8,
    border: (props) => props.border || 0,
    boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px 0px, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px',
    backgroundColor: (props) => props?.background?.backgroundColor || theme.palette.common.white,
    backgroundImage: (props) => (props?.background?.backgroundImage ? `url(${props?.background?.backgroundImage})` : null),
    backgroundSize: (props) => props?.background?.size,
    backgroundRepeat: (props) => props?.background?.repeat,
    backgroundPosition: (props) => props?.background?.position,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}));

const CustomPaper = ({ children, hidden, ...props }) => {
  const classes = useStyles(props);

  return (
    <Paper className={classes.paper} hidden={hidden}>
      {children}
    </Paper>
  );
};

CustomPaper.defaultProps = {
  hidden: false,
};

CustomPaper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hidden: PropTypes.bool,
};

export default CustomPaper;
