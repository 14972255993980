import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import { palette } from '../../theme';
import { useUser } from '../../contexts/UserContext';
import { companyTypes } from '../../constants';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightRegular,
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  linkButton: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '13px 0px',
    borderLeft: '10px solid transparent',
    '&:hover': {
      backgroundColor: palette.background.default,
    },
  },
  activeLinkButton: {
    borderLeft: '10px solid #29B473',
    padding: '13px 0px',
    background: palette.background.default,
    '&:hover': {
      backgroundColor: palette.background.default,
    },
  },
  materialIcon: {
    color: '#353535',
  },
  activeMaterialIcon: {
    color: 'white',
  },
}));

const DrawerLink = ({
  icon: Icon,
  label,
  to,
  onClick,
  rolePermission,
}) => {
  const classes = useStyles();
  const route = useRouteMatch();
  const toPath = to.split('/');
  let validator = route.path.split('/', 3).pop();
  const { accountType } = useParams();

  if (validator === 'loan') {
    validator = 'loans';
  }
  if (validator === 'subContractor') {
    validator = 'dashboard';
  }
  if (validator === 'user') {
    validator = 'users';
  }
  if (validator === 'project') {
    validator = 'projects';
  }
  if (validator === 'application') {
    validator = 'home';
  }
  if (validator === 'scs' || validator === 'gcs' || validator === 'scCreation') {
    validator = 'companies';
  }
  if (validator === 'payments') {
    validator = 'payments';
  }
  if (accountType === 'struxtion') {
    validator = 'struxtionAccounts';
  }
  if (accountType === 'operating') {
    validator = 'operatingAccounts';
  }
  if (validator === 'home') {
    validator = 'home';
  }
  if (validator === 'struxtionAccount') {
    validator = 'struxtionAccount';
  }

  const isActive = (toPath.includes(validator));

  // CHECKING AVAILABLE USER ROLES BY NAV ROLES
  const {
    user: {
      companyType, isEppCompany, struxtionRoles, companyId,
    },
  } = useUser();

  const userHasPermission = () => struxtionRoles.filter((role) => role.companyId === companyId).map((r) => r.name).some((role) => rolePermission.includes(role));

  const isXPayGC = companyType === companyTypes.GENERAL_CONTRACTOR && isEppCompany;

  const OptionLink = () => (
    <Link className={classes.link} to={to} onClick={onClick}>
      <ListItem button className={isActive ? `${classes.activeLinkButton} activeLinkButton` : classes.linkButton}>
        {Icon && (
        <ListItemIcon className={classes.linkIcon}>
          <Icon white={isActive} />
        </ListItemIcon>
        )}
        <ListItemText primaryTypographyProps={{ className: classes.listItemText }} primary={label} />
      </ListItem>
    </Link>
  );

  // ROLES IS ONLY FOR XPAY GC FOR NOW
  if (!isXPayGC) {
    return (<OptionLink />);
  } if (isXPayGC && struxtionRoles.length > 0 && userHasPermission()) {
    return (<OptionLink />);
  } return null;
};

DrawerLink.defaultProps = {
  icon: undefined,
  onClick: () => { },
  rolePermission: [],
};

DrawerLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  rolePermission: PropTypes.array,
};

export default DrawerLink;
