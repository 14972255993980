import React from 'react';
import {
  Typography, Box, LinearProgress,
} from '@material-ui/core';
import StruxtionLogo from '../assets/icons/StruxtionLogo.svg';

const LoadingPage = () => (
  <>
    <LinearProgress color="primary" />
    <Box
      sx={{
        height: '98vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ pb: 3 }}>
        <img width={240} src={StruxtionLogo} alt="StruxtionLogo" loading="lazy" />
      </Box>
      <Box sx={{ pb: 3 }}>
        <Typography variant="body1">Redirecting...</Typography>
      </Box>
    </Box>
  </>
);

export default LoadingPage;
