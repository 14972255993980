/* global gtag */

export const setGtagConfig = (pageTitle, pagePath) => {
  gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID, {
    page_title: pageTitle,
    page_path: pagePath,
  });
};

export const setGtagUser = (id) => {
  gtag('set', { userId: id });
};

export const setGtagCompany = (id, name) => {
  gtag('set', {
    companyId: id,
    companyName: name,
  });
};

export const setGtagDataLayer = (params) => {
  gtag('set', params);
};

export const triggerGtagEvent = (name, params) => {
  gtag('event', name, params);
};

export const triggerGtagValidationEvent = (name, params) => {
  triggerGtagEvent(`Validation: ${name}`, params);
};

export const setGtagCookie = () => {
  gtag('set', { cookie_flags: 'SameSite=None;Secure' });
};
