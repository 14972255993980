/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { AppBarWithoutDrawer } from './AppBarDrawer';

const Legal = ({ children }) => (
  <AppBarWithoutDrawer hideLogo={false}>
    { children }
  </AppBarWithoutDrawer>
);

Legal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Legal;
