import axios from 'axios';

export const uploadDocumentsSelector = (state) => state.uploadDocuments;

const UPLOAD_DOCUMENTS_RESET = 'UPLOAD_DOCUMENTS_RESET';
const UPLOAD_DOCUMENTS_REQUEST = 'UPLOAD_DOCUMENTS_REQUEST';
const UPLOAD_DOCUMENTS_OK = 'UPLOAD_DOCUMENTS_OK';
const UPLOAD_DOCUMENTS_ERROR = 'UPLOAD_DOCUMENTS_ERROR';

export const uploadDocumentsReset = () => ({
  type: UPLOAD_DOCUMENTS_RESET,
});

export const uploadDocumentsRequest = () => ({
  type: UPLOAD_DOCUMENTS_REQUEST,
});

export const uploadDocumentsOk = (payload) => ({
  type: UPLOAD_DOCUMENTS_OK,
  payload,
});

export const uploadDocumentsError = (error) => ({
  type: UPLOAD_DOCUMENTS_ERROR,
  error,
});

export const uploadDocumentsResetThunk = () => (dispatch) => {
  dispatch(uploadDocumentsReset());
};

export const uploadDocumentsThunk = (documents, metaData, onSuccess) => (dispatch) => {
  dispatch(uploadDocumentsRequest());
  const formData = new FormData();
  documents.forEach((doc) => {
    formData.append('files', doc);
  });
  formData.append('fileInfo', JSON.stringify(metaData));

  return axios.post('/api/doc/file', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(({ data }) => {
      dispatch(uploadDocumentsOk(data));
      onSuccess(data);
    })
    .catch((error) => {
      let message = 'Failed to upload the selected documents.';

      if (error?.response?.status === 413) {
        message += ' File size exceeded 20MB.';
      }

      dispatch(uploadDocumentsError(message));
    });
};

export const initialState = {
  uploading: false,
  uploaded: false,
  error: '',
};

const documents = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENTS_REQUEST:
      return { ...initialState, uploading: true };
    case UPLOAD_DOCUMENTS_OK:
      return { ...initialState, uploaded: true };
    case UPLOAD_DOCUMENTS_ERROR:
      return { ...initialState, error: action.error };
    case UPLOAD_DOCUMENTS_RESET:
      return initialState;
    default:
      return state;
  }
};

export default documents;
