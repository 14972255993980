import React from 'react';
import {
  Button, Grid, Typography, Box, Divider,
} from '@material-ui/core';
import CustomPaper from './MaterialUI/CustomPaper';
import { useKeycloak } from '../contexts/KeycloakContext';
import StruxtionLogo from '../assets/icons/StruxtionLogo.svg';

const AuthError = () => {
  const { keycloak } = useKeycloak();

  const redirect = () => {
    window.location.reload();
  };

  const handleLogout = () => keycloak.logout();
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        height: '100vh',
      }}
    >
      <Grid xs={10} md={6} item>
        <CustomPaper>
          <Box sx={{ pb: 3 }} display="flex" alignItems="center" justifyContent="flex-start">
            <img width={120} src={StruxtionLogo} alt="StruxtionLogo" loading="lazy" />
          </Box>
          <Typography variant="body1">There has been an error</Typography>
          <Box sx={{ py: 2 }}>
            <Divider />
          </Box>
          <Box
            display="flex"
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mt: 3, gap: 10 }}
          >
            <Button disableElevation variant="contained" color="primary" onClick={() => redirect()}>Reload</Button>
            <Button disableElevation variant="contained" color="inherit" onClick={handleLogout}>Logout</Button>
          </Box>
        </CustomPaper>
      </Grid>
    </Grid>
  );
};

export default AuthError;
