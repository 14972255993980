import axios from 'axios';

export const updateUserSelector = (state) => state.registration.updateUser;

const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
const UPDATE_USER_OK = 'UPDATE_USER_OK';
const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const updateUserRequest = () => ({
  type: UPDATE_USER_REQUEST,
});

export const updateUserOk = (payload) => ({
  type: UPDATE_USER_OK,
  payload,
});

export const updateUserError = (error) => ({
  type: UPDATE_USER_ERROR,
  error,
});

export const updateUserThunk = (user, setUser, formData, callback) => (dispatch) => {
  dispatch(updateUserRequest());

  return axios.put(`/api/user/${user.id}`, { ...user, ...formData })
    .then(({ data }) => {
      callback();
      dispatch(updateUserOk(data));
      setUser({
        ...user,
        ...data,
      });
    })
    .catch(() => { dispatch(updateUserError('Failed to update user, please try again.')); });
};

export const initialState = {
  loading: false,
  loaded: false,
  error: null,
  user: {},
};

const updateUser = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return { ...initialState, loading: true };
    case UPDATE_USER_OK:
      return { ...initialState, loaded: true, user: action.payload };
    case UPDATE_USER_ERROR:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};

export default updateUser;
