import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Slider,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Cropper from 'react-easy-crop';
import PreviewPhoto from './PreviewPhoto';
import { getCroppedImg } from '../../utils/canvasUtils';

const useStyles = makeStyles((theme) => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      height: 200,
    },
  },
  controls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function CropComponent({ imageSrc, setNewImage }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [croppedImage, setCroppedImage] = useState(null);
  const [error, setError] = useState('');
  const [cropSize, setCropSize] = useState({ width: 0, height: 0 });

  const classes = useStyles();

  const onCropComplete = useCallback((_, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImg = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
      );
      setNewImage(croppedImg.blob);
      setCroppedImage(croppedImg.objectURL);
    } catch (e) {
      setError(e);
    }
  }, [imageSrc, croppedAreaPixels, setNewImage, rotation]);

  const onMediaLoaded = (media) => {
    setCropSize({ width: media.width, height: media.height });
  };

  return (
    <>
      {error && (<Alert severity="error">{error}</Alert>)}
      <div className={classes.cropContainer}>
        <Cropper
          image={imageSrc}
          crop={crop}
          style={{
            cropAreaStyle: {
              color: '#ffffff',
              border: '2px solid rgb(0 0 0 / 50%)',
            },
          }}
          rotation={rotation}
          zoom={zoom}
          showGrid={false}
          cropSize={cropSize}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onMediaLoaded={onMediaLoaded}
        />
      </div>
      <Box>
        <Box display="flex" alignItems="center">
          <div className={classes.controls}>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zm) => setZoom(zm)}
              />
            </div>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Rotation
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                classes={{ root: classes.slider }}
                onChange={(e, rt) => setRotation(rt)}
              />
            </div>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              color="primary"
              classes={{ root: classes.cropButton }}
            >
              Show Result
            </Button>
          </div>
        </Box>
        <Box mt={2} display="flex">
          {croppedImage && <PreviewPhoto image={croppedImage} />}
        </Box>
      </Box>
    </>
  );
}

CropComponent.defaultProps = {
  setNewImage: () => { },
};

CropComponent.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  setNewImage: PropTypes.func,
};
