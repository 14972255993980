import axios from 'axios';

export const createLoanSelector = (state) => state.registration.createLoan;

const CREATE_LOAN_REQUEST = 'CREATE_LOAN_REQUEST';
const CREATE_LOAN_OK = 'CREATE_LOAN_OK';
const CREATE_LOAN_ERROR = 'CREATE_LOAN_ERROR';

export const createLoanRequest = () => ({
  type: CREATE_LOAN_REQUEST,
});

export const createLoanOk = () => ({
  type: CREATE_LOAN_OK,
});

export const createLoanError = (error) => ({
  type: CREATE_LOAN_ERROR,
  error,
});

export const createLoanThunk = (user, onSuccess) => (dispatch) => {
  dispatch(createLoanRequest());

  return axios.post('/api/loan', { companyId: user.companyId })
    .then(() => {
      dispatch(createLoanOk());
      onSuccess();
    })
    .catch(() => { dispatch(createLoanError('Error, please try again.')); });
};

export const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

const createLoan = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_LOAN_REQUEST:
      return { ...initialState, loading: true };
    case CREATE_LOAN_OK:
      return { ...initialState, loaded: true };
    case CREATE_LOAN_ERROR:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};

export default createLoan;
