import { combineReducers } from 'redux';
import subContractors from './SubContractors';
import docTypes from './DocTypes';
import documents from './Documents';
import loanAppDocuments from './LoanAppDocuments';
import uploadDocuments from './UploadDocuments';
import registration from './Registration';
import statements from './Statements';
import payApp from './PaymentApplication';
import updatePayApp from './UpdatePaymentApplication';
import updateDocument from './UpdateDocument';

export default combineReducers({
  docTypes,
  documents,
  loanAppDocuments,
  subContractors,
  uploadDocuments,
  registration,
  statements,
  payApp,
  updatePayApp,
  updateDocument,
});
