import axios from 'axios';

export const updatePayAppSelector = (state) => state.updatePayApp;

const UPDATE_PAYAPP_REQUEST = 'UPDATE_PAYAPP_REQUEST';
const UPDATE_PAYAPP_OK = 'UPDATE_PAYAPP_OK';
const UPDATE_PAYAPP_ERROR = 'UPDATE_PAYAPP_ERROR';

export const updatePayAppRequest = () => ({
  type: UPDATE_PAYAPP_REQUEST,
});

export const updatePayAppOk = (payload) => ({
  type: UPDATE_PAYAPP_OK,
  payload,
});

export const updatePayAppError = (error) => ({
  type: UPDATE_PAYAPP_ERROR,
  error,
});

export const updatePayAppThunk = (payApp, onSuccess = () => {}) => (dispatch) => {
  dispatch(updatePayAppRequest());

  const updatePayApp = {
    ...payApp,
    paymentAppDocId: payApp.paymentAppDoc?.id,
  };

  return axios.put(`/api/payapp/${payApp.id}`, updatePayApp)
    .then(({ data }) => {
      dispatch(updatePayAppOk(data));
      onSuccess(data);
    })
    .catch(() => {
      dispatch(updatePayAppError('Failed to update payment application.'));
    });
};

export const initialState = {
  loading: false,
  loaded: false,
  payApp: {},
  error: '',
};

const updatePayApp = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAYAPP_REQUEST:
      return { ...initialState, loading: true };
    case UPDATE_PAYAPP_OK:
      return { ...initialState, loaded: true, payApp: action.payload };
    case UPDATE_PAYAPP_ERROR:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};

export default updatePayApp;
