import axios from 'axios';

export const payAppSelector = (state) => state.payApp;

export const GET_PAYAPP_REQUEST = 'GET_PAYAPP_REQUEST';
const GET_PAYAPP_OK = 'GET_PAYAPP_OK';
const GET_PAYAPP_ERROR = 'GET_PAYAPP_ERROR';

export const getPayAppRequest = () => ({
  type: GET_PAYAPP_REQUEST,
});

export const getPayAppOk = (payload) => ({
  type: GET_PAYAPP_OK,
  payload,
});

export const getPayAppError = (error) => ({
  type: GET_PAYAPP_ERROR,
  error,
});

export const getPayAppThunk = (payAppId) => (dispatch) => {
  dispatch(getPayAppRequest());

  return axios.get(`/api/payapp/${payAppId}`)
    .then(({ data }) => {
      dispatch(getPayAppOk(data));
    })
    .catch(() => {
      dispatch(getPayAppError('Failed to retrieve payment application.'));
    });
};

export const initialState = {
  loading: false,
  loaded: false,
  payApp: {},
  error: '',
};

const payApp = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYAPP_REQUEST:
      return { ...initialState, loading: true };
    case GET_PAYAPP_OK:
      return { ...initialState, loaded: true, payApp: action.payload };
    case GET_PAYAPP_ERROR:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};

export default payApp;
