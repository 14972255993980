import axios from 'axios';

export const loanAppDocumentsSelector = (state) => state.loanAppDocuments;

const GET_LOAN_APP_DOCUMENTS_RESET = 'GET_LOAN_APP_DOCUMENTS_RESET';
const GET_LOAN_APP_DOCUMENTS_REQUEST = 'GET_LOAN_APP_DOCUMENTS_REQUEST';
const GET_LOAN_APP_DOCUMENTS_OK = 'GET_LOAN_APP_DOCUMENTS_OK';
const GET_LOAN_APP_DOCUMENTS_ERROR = 'GET_LOAN_APP_DOCUMENTS_ERROR';

export const getLoanAppDocumentsReset = () => ({
  type: GET_LOAN_APP_DOCUMENTS_RESET,
});

export const getLoanAppDocumentsRequest = () => ({
  type: GET_LOAN_APP_DOCUMENTS_REQUEST,
});

export const getLoanAppDocumentsOk = (payload) => ({
  type: GET_LOAN_APP_DOCUMENTS_OK,
  payload,
});

export const getLoanAppDocumentsError = (error) => ({
  type: GET_LOAN_APP_DOCUMENTS_ERROR,
  error,
});

export const getLoanAppDocumentsResetThunk = () => (dispatch) => {
  dispatch(getLoanAppDocumentsReset());
};

export const getLoanAppDocumentsThunk = (companyId, params = {}, onSuccess = () => {}) => (dispatch) => {
  dispatch(getLoanAppDocumentsRequest());

  const queryParams = [];
  queryParams.push(`companyId=${companyId}`);
  Object.keys(params).forEach((p) => {
    queryParams.push(`${p}=${params[p]}`);
  });

  return axios.get(`/api/loanappwizard?${queryParams.join('&')}`)
    .then(({ data }) => {
      dispatch(getLoanAppDocumentsOk(data));
      onSuccess();
    })
    .catch(() => {
      dispatch(getLoanAppDocumentsError('Failed to retrieve loan application documents.'));
    });
};

export const initialState = {
  loading: false,
  loaded: false,
  loanAppDocuments: [],
  error: '',
};

const loanAppDocuments = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOAN_APP_DOCUMENTS_REQUEST:
      return { ...initialState, loading: true };
    case GET_LOAN_APP_DOCUMENTS_OK:
      return { ...initialState, loaded: true, loanAppDocuments: action.payload?.tasks || [] };
    case GET_LOAN_APP_DOCUMENTS_ERROR:
      return { ...initialState, error: action.error };
    case GET_LOAN_APP_DOCUMENTS_RESET:
      return initialState;
    default:
      return state;
  }
};

export default loanAppDocuments;
