import { withStyles } from '@material-ui/core';

export const global = {
  '.stretch': {
    width: '100%',
    height: '100%',
  },
  '@font-face': {
    'font-family': 'Password',
    'font-style': 'normal',
    'font-display': 'swap',
    'font-weight': 400,
    src: 'url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf)',
  },
  'input.mask': {
    'font-family': 'Password',
  },
};

export default withStyles({
  '@global': global,
})(() => null);
