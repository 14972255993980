import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useLocation, useHistory } from 'react-router-dom';
import {
  LinearProgress, Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography, FormControl, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useSwitchCompany from '../../api/useSwitchCompany';
import { useUser } from '../../contexts/UserContext';
import { orderRelatedCompanies } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '500px',
    maxWidth: '100%',
  },
  order: {
    backgroundColor: theme.palette.background.paper,
  },
  mainCompanyAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));

const SwitchCompanyModal = ({
  open, setModal,
}) => {
  const classes = useStyles();
  const { user } = useUser();
  const { pathname } = useLocation();
  const history = useHistory();

  const [newOrderCompanies, setNewOrderCompanies] = useState([]);
  const [value, setValue] = React.useState(user.companyId);

  const {
    loading,
    loaded,
    error,
    companies,
    getContactCompaniesOrder,
    getCompanyInfo,
  } = useSwitchCompany();

  useEffect(() => {
    getContactCompaniesOrder(user.contactId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderCompanies = useCallback((companiesList) => {
    setNewOrderCompanies(orderRelatedCompanies(companiesList));
  }, []);

  useEffect(() => {
    orderCompanies(companies);
  }, [orderCompanies, companies]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setModal(false);
  };

  const redirectAfterSwitch = () => {
    const fullPathname = pathname.split('/');
    const mainPathname = fullPathname[1];
    const parentPathname = fullPathname[2];
    history.push(`/${mainPathname}/${parentPathname}`);
    handleClose();
    return window.location.reload();
  };

  const handleConfirm = () => {
    getCompanyInfo(user.id, value, () => redirectAfterSwitch());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="offered-dialog-title"
    >
      <DialogTitle id="offered-dialog-title">Switch company</DialogTitle>
      <DialogContent>
        {error && <Alert severity="warning">{error}</Alert>}
        <Grid item xs={12}>
          <div>
            <Typography>
              All Companies
            </Typography>
            {loading && <LinearProgress />}
            {
              loaded && newOrderCompanies.length && (
              <FormControl className={classes.root}>
                <RadioGroup aria-label="switch-company" name="selectedCompany" value={value} onChange={handleChange}>
                  {newOrderCompanies.map((company) => (
                    <FormControlLabel key={company.companyId} value={company.companyId} control={<Radio />} label={company.companyName} />
                  ))}
                </RadioGroup>
              </FormControl>
              )
            }
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          onClick={handleConfirm}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
        <Button
          disableElevation
          onClick={handleClose}
          variant="contained"
          color="inherit"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SwitchCompanyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
};

export default SwitchCompanyModal;
