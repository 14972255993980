import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Grid,
  IconButton,
  ListItemText,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  LinearProgress,
  Avatar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useSwitchCompany from '../../api/useSwitchCompany';
import { useUser } from '../../contexts/UserContext';
import { orderRelatedCompanies } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '500px',
    maxWidth: '100%',
  },
  order: {
    backgroundColor: theme.palette.background.paper,
  },
  mainCompanyAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));

const MOVE_TO_UP = 'up';
const MOVE_TO_DOWN = 'down';

const PreferredCompaniesModal = ({ open, setModal }) => {
  const classes = useStyles();
  const { user, setUser } = useUser();

  const {
    loading,
    loaded,
    error,
    companies,
    getContactCompaniesOrder,
    changeContactCompaniesOrder,
  } = useSwitchCompany();

  const [newOrderCompanies, setNewOrderCompanies] = useState([]);

  useEffect(() => {
    getContactCompaniesOrder(user.contactId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderCompanies = useCallback((companiesList) => {
    setNewOrderCompanies(orderRelatedCompanies(companiesList));
  }, []);

  useEffect(() => {
    orderCompanies(companies);
  }, [orderCompanies, companies]);

  const handleClose = () => {
    setModal(false);
  };

  const handleReorder = (currentOrder, moveType) => {
    const companiesArray = [...newOrderCompanies];
    if (moveType === MOVE_TO_UP) {
      companiesArray[currentOrder - 1].sequenceNumber = currentOrder - 1;
      companiesArray[currentOrder - 2].sequenceNumber = currentOrder;
      orderCompanies(companiesArray);
    } else {
      companiesArray[currentOrder - 1].sequenceNumber = currentOrder + 1;
      companiesArray[currentOrder].sequenceNumber = currentOrder;
      orderCompanies(companiesArray);
    }
  };

  const onSubmitReorder = () => {
    changeContactCompaniesOrder(newOrderCompanies, () => {
      setUser({
        ...user,
        relatedCompanies: orderRelatedCompanies(newOrderCompanies).map(
          (company) => ({
            id: company.companyId,
            name: company.companyName,
            sequenceNumber: company.sequenceNumber,
          }),
        ),
      });
      handleClose();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="offered-dialog-title"
    >
      {loading && <LinearProgress />}
      <DialogTitle id="offered-dialog-title">Preferred companies</DialogTitle>
      {error && <Alert severity="warning">{error}</Alert>}
      {}
      {loaded && newOrderCompanies.length && (
        <>
          <DialogContent>
            <Grid item xs={12}>
              <div>
                <List className={classes.root}>
                  {newOrderCompanies.map((company) => (
                    <ListItem key={company.id}>
                      <ListItemAvatar>
                        <Avatar
                          className={
                            company.sequenceNumber === 1
                              ? classes.mainCompanyAvatar
                              : null
                          }
                        >
                          {company.sequenceNumber}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={company.companyName}
                        secondary={
                          company.sequenceNumber === 1
                            ? 'Default company'
                            : null
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          disabled={company.sequenceNumber === 1}
                          onClick={() => handleReorder(company?.sequenceNumber, MOVE_TO_UP)}
                          edge="end"
                          aria-label="move to up"
                        >
                          <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton
                          disabled={
                            company.sequenceNumber === newOrderCompanies.length
                          }
                          onClick={() => handleReorder(company?.sequenceNumber, MOVE_TO_DOWN)}
                          edge="end"
                          aria-label="move to down"
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disableElevation
              onClick={onSubmitReorder}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button
              disableElevation
              onClick={handleClose}
              variant="contained"
              color="inherit"
            >
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

PreferredCompaniesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
};

export default PreferredCompaniesModal;
