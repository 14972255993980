import axios from 'axios';

export const docTypesSelector = (state) => state.docTypes;

const GET_DOC_TYPES_RESET = 'GET_DOC_TYPES_RESET';
const GET_DOC_TYPES_REQUEST = 'GET_DOC_TYPES_REQUEST';
const GET_DOC_TYPES_OK = 'GET_DOC_TYPES_OK';
const GET_DOC_TYPES_ERROR = 'GET_DOC_TYPES_ERROR';

export const getDocTypesReset = () => ({
  type: GET_DOC_TYPES_RESET,
});

export const getDocTypesRequest = () => ({
  type: GET_DOC_TYPES_REQUEST,
});

export const getDocTypesOk = (payload) => ({
  type: GET_DOC_TYPES_OK,
  payload,
});

export const getDocTypesError = (error) => ({
  type: GET_DOC_TYPES_ERROR,
  error,
});

export const getDocTypesResetThunk = () => (dispatch) => {
  dispatch(getDocTypesReset());
};

export const getDocTypesThunk = (onSuccess = () => {}) => (dispatch) => {
  dispatch(getDocTypesRequest());

  return axios.get('/api/doc/type')
    .then(({ data }) => {
      dispatch(getDocTypesOk(data));
      onSuccess();
    })
    .catch(() => {
      dispatch(getDocTypesError('Failed to retrieve doc types.'));
    });
};

export const initialState = {
  loading: false,
  loaded: false,
  docTypes: [],
  error: '',
};

const docTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOC_TYPES_REQUEST:
      return { ...initialState, loading: true };
    case GET_DOC_TYPES_OK:
      return { ...initialState, loaded: true, docTypes: action.payload || [] };
    case GET_DOC_TYPES_ERROR:
      return { ...initialState, error: action.error };
    case GET_DOC_TYPES_RESET:
      return initialState;
    default:
      return state;
  }
};

export default docTypes;
