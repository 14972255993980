import { combineReducers } from 'redux';
import aboutCompany from './AboutCompany';
import saveCompanyAndUser from './SaveCompanyAndUser';
import einLookup from './EINLookup';
import updateUser from './UpdateUser';
import createLoan from './CreateLoan';

export default combineReducers({
  aboutCompany,
  saveCompanyAndUser,
  einLookup,
  updateUser,
  createLoan,
});
