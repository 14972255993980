import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  imgContainer: {
    position: 'relative',
    flex: 1,
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
    width: '400px',
    borderRadius: '50%',
    backgroundColor: '#bdbdbd',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default function PreviewPhoto({ image }) {
  const classes = useStyles();
  return (
    <div className={classes.imgContainer}>
      <img src={image} alt="Cropped" className={classes.img} />
    </div>
  );
}

PreviewPhoto.defaultProps = {
  image: '',
};

PreviewPhoto.propTypes = {
  image: PropTypes.string,
};
