import React from 'react';
import axios from 'axios';

function useGetAvatar() {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [avatar, setAvatar] = React.useState(null);
  const [error, setError] = React.useState('');

  const getAvatar = React.useCallback((id, onSuccess = () => { }) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setAvatar('');

    axios({ method: 'get', url: `/api/doc/file/${id}`, responseType: 'blob' })
      .then(({ data }) => {
        setLoading(false);
        setAvatar(URL.createObjectURL(data));
        setLoaded(true);
        onSuccess(data);
      })
      .catch((ex) => {
        let message = 'Failed to get the requested profile photo.';

        if (ex?.response?.status === 413) {
          message += ' File(s) size exceeded 50MB.';
        }

        setLoading(false);
        setError(message);
      });
  }, []);

  return {
    loading,
    loaded,
    error,
    getAvatar,
    avatar,
  };
}

export default useGetAvatar;
