import React from 'react';
import axios from 'axios';

function useSwitchCompany() {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [company, setCompany] = React.useState(null);
  const [companies, setCompanies] = React.useState([]);
  const [error, setError] = React.useState('');

  const getCompanyInfo = React.useCallback((userId, companyId, onSuccess = () => { }) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setCompany('');

    axios({ method: 'get', url: `/api/user/${userId}/switchCompany/${companyId}` })
      .then(({ data }) => {
        setLoading(false);
        setCompany(data);
        setLoaded(true);
        onSuccess(data);
      })
      .catch(() => {
        setLoading(false);
        setError('Failed to get the requested company.');
      });
  }, []);

  const getContactCompaniesOrder = React.useCallback((contactId, onSuccess = () => { }) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setCompanies([]);

    axios({ method: 'get', url: `/api/xpay/contact/${contactId}/getAllCompaniesOrder` })
      .then(({ data }) => {
        setLoading(false);
        setCompanies(data);
        setLoaded(true);
        onSuccess(data);
      })
      .catch(() => {
        setLoading(false);
        setError('Failed to get the requested companies list.');
      });
  }, []);

  const changeContactCompaniesOrder = React.useCallback((body, onSuccess = () => { }) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    // setCompanies([]);

    axios.put('/api/xpay/contact/updateRelatedCompaniesOrder', body)
      .then(() => {
        setLoading(false);
        setCompanies(body);
        setLoaded(true);
        onSuccess(body);
      })
      .catch(() => {
        setLoading(false);
        setError('Failed to update the order from companies list.');
      });
  }, []);

  return {
    loading,
    loaded,
    error,
    getCompanyInfo,
    getContactCompaniesOrder,
    changeContactCompaniesOrder,
    company,
    companies,
  };
}

export default useSwitchCompany;
