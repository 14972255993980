import React from 'react';
import PropTypes from 'prop-types';

const KeycloakContext = React.createContext();

export const KeycloakProvider = ({ children, value }) => {
  const [keycloak, setKeycloak] = React.useState({});

  return (
    <KeycloakContext.Provider
      value={value || {
        keycloak,
        setKeycloak,
      }}
    >
      {children}
    </KeycloakContext.Provider>
  );
};

export const useKeycloak = () => {
  const context = React.useContext(KeycloakContext);
  if (context === undefined) {
    throw new Error('useKeycloak must be used within a KeycloakProvider');
  }

  return context;
};

KeycloakProvider.defaultProps = {
  value: undefined,
};

KeycloakProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    keycloak: PropTypes.shape({
      authenticated: PropTypes.bool,
      logout: PropTypes.func,
      refresh: PropTypes.func,
    }),
    setKeycloak: PropTypes.func,
  }),
};
