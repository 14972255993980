import React, { useCallback } from 'react';
import axios from 'axios';

function useLoan() {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState('');
  const [loan, setLoan] = React.useState({});

  const getLoan = useCallback((id) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setLoan({});

    axios.get(`/api/loan/${id}`)
      .then(({ data }) => {
        setLoading(false);
        setLoan(data);
        setLoaded(true);
      })
      .catch(() => {
        setLoading(false);
        setError('Failed to retrieve loan.');
      });
  }, []);

  const getActiveLoan = useCallback((companyId) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setLoan({});

    axios.get(`/api/loan/active?companyId=${companyId}`)
      .then(({ data }) => {
        setLoading(false);
        setLoan(data);
        setLoaded(true);
      })
      .catch((ex) => {
        let message = 'Failed to retrieve loan.';
        if (ex?.response?.status === 404) {
          message += ' There is no active loan.';
        }
        setLoading(false);
        setError(message);
      });
  }, []);

  const updateLoan = (updatedLoan, onSuccess = () => { }) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setLoan({});

    axios.put(`/api/loan/${updatedLoan.id}`, { ...updatedLoan })
      .then(({ data }) => {
        setLoading(false);
        setLoan(data);
        setLoaded(true);
        onSuccess(data);
      })
      .catch(() => {
        setLoading(false);
        setError('Failed to update loan.');
      });
  };

  const updateLoanStatus = (updatedLoan, status, onSuccess) => updateLoan({ ...updatedLoan, status }, onSuccess);

  return {
    loading,
    loaded,
    error,
    loan,
    getLoan,
    getActiveLoan,
    updateLoan,
    updateLoanStatus,
  };
}

export default useLoan;
