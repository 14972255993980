/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Paper,
  Typography,
  Modal,
  makeStyles,
  Grid,
  Avatar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { FormProvider, useForm } from 'react-hook-form';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getOrientation } from 'get-orientation/browser';
import { BasicIcons } from '../icons';
import CropComponent from './CropComponent';
import { getRotatedImage } from '../../utils/canvasUtils';
import useUploadDocuments from '../../api/uploadDocuments';
import useGetAvatar from '../../api/useGetAvatar';
import useGetDocuments from '../../api/getDocuments';
import { useUser } from '../../contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    maxHeight: '90vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '1em',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  large: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  profileIcon: {
    height: 300,
    width: 300,
    margin: '0 auto',
    padding: '0.2em',
    backgroundColor: theme.palette.text.secondary,
    borderRadius: '50%',
  },
  image: {
    color: 'transparent',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    textAlign: 'center',
    textIndent: '10000px',
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

export default function ChangePhotoModal({
  open,
  onCancel,
  logo,
  setProfilePhoto,
}) {
  const classes = useStyles();

  const [imageSrc, setImageSrc] = useState(null);
  const [newImage, setNewImage] = useState(null);

  const { user } = useUser();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));

  const {
    error: errorUploadingDocuments,
    loading,
    loaded,
    uploadDocuments,
  } = useUploadDocuments();

  const {
    error: errorAvatar,
    avatar,
    getAvatar,
  } = useGetAvatar();

  const {
    error: errorDocuments,
    getDocuments,
    documents,
  } = useGetDocuments();

  const {
    handleSubmit,
    methods,
  } = useForm();

  const handleClose = () => {
    onCancel();
    setImageSrc(null);
  };

  const onSubmit = () => {
    const fileImage = new File([newImage], 'photo-profile.png', { type: 'image/*' });
    let meta = { docs: [{ docType: 'COMPANY_LOGO', status: 'new', quality: null }], companyId: user.companyId };
    if (documents?.length > 0) {
      meta = {
        docs: [{
          docType: 'COMPANY_LOGO', status: 'new', quality: null, docId: documents[0]?.id,
        }],
        companyId: user.companyId,
      };
    }
    uploadDocuments([fileImage], meta);
  };

  useEffect(() => {
    if (loaded) window.location.reload();
  }, [loaded]);

  useEffect(() => {
    if (documents.length > 0) getAvatar(documents[0]?.id);
  }, [documents, getAvatar]);

  useEffect(() => {
    const docType = 'COMPANY_LOGO';
    if (user?.companyId) {
      getDocuments(user?.companyId, { docType });
    }
  }, [getDocuments, user?.companyId]);

  useEffect(() => {
    setProfilePhoto(avatar);
  }, [setProfilePhoto, avatar]);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setImageSrc(imageDataUrl);
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="gc-verification-modal"
    >
      <Paper square elevation={24} className={classes.paper}>
        <Box width={!isMobile ? '90%' : '600px'} padding={!isMobile ? 2 : 4}>
          <FormProvider {...methods}>
            {(errorUploadingDocuments || errorAvatar || errorDocuments) && (<Alert severity="error">{errorUploadingDocuments || errorAvatar || errorDocuments}</Alert>)}
            {!imageSrc && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h4" style={{ marginBottom: '16px', textAlign: 'center' }}>PROFILE PHOTO</Typography>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  <Box>
                    {logo ? (
                      <Avatar className={classes.large}>
                        <img className={classes.image} src={logo} alt="profile" />
                      </Avatar>
                    ) : <BasicIcons.Person className={classes.profileIcon} white />}
                  </Box>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  <Box mt={4}>
                    <div className={classes.root}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="uploadProfile"
                        multiple
                        type="file"
                        onChange={onFileChange}
                      />
                      <label htmlFor="uploadProfile">
                        <Button variant="contained" color="primary" component="span">
                          Upload
                        </Button>
                      </label>
                    </div>
                  </Box>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  <Box mt={2}>
                    <Button onClick={handleClose}>CANCEL</Button>
                  </Box>
                </Grid>
              </form>
            )}
            {imageSrc && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h4" style={{ marginBottom: '16px', textAlign: 'center' }}>CROP PHOTO</Typography>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  <CropComponent imageSrc={imageSrc} setNewImage={setNewImage} />
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  <Box>
                    <Button disabled={!newImage || loading} type="submit" color="primary" variant="contained" style={{ margin: '32px 0 0 0' }}>SAVE</Button>
                  </Box>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  <Box mt={4}>
                    <Button onClick={handleClose}>CANCEL</Button>
                  </Box>
                </Grid>
              </form>
            )}
          </FormProvider>
        </Box>
      </Paper>
    </Modal>
  );
}

ChangePhotoModal.defaultProps = {
  logo: '',
  setProfilePhoto: () => { },
};

ChangePhotoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  logo: PropTypes.string,
  setProfilePhoto: PropTypes.func,
};
