import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Divider, Grid, Typography, Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { eppGC } from '../constants/navigation';
import { useUser } from '../contexts/UserContext';
import { companyTypes } from '../constants';
import { useKeycloak } from '../contexts/KeycloakContext';
import CustomPaper from './MaterialUI/CustomPaper';
import StruxtionLogo from '../assets/icons/StruxtionLogo.svg';

const AccessRestrictedError = ({ routePermission }) => {
  const history = useHistory();
  const { keycloak } = useKeycloak();

  const { user: { companyType, isEppCompany, roles } } = useUser();

  const hasRolPermission = () => routePermission.some((element) => roles.includes(element));

  const redirect = () => {
    if (!hasRolPermission()) keycloak.logout();
    if (isEppCompany && companyType === companyTypes.GENERAL_CONTRACTOR) return history.push(`${eppGC}/dashboard`);
    return history.push('/');
  };

  const handleLogout = () => keycloak.logout();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        height: '100vh',
      }}
    >
      <Grid xs={10} md={6} item>
        <CustomPaper>
          <Box sx={{ pb: 3 }} display="flex" alignItems="center" justifyContent="flex-start">
            <img width={120} src={StruxtionLogo} alt="StruxtionLogo" loading="lazy" />
          </Box>
          <Typography variant="h2">Access Restricted</Typography>
          <Typography variant="body1">You don&rsquo;t have permissions to view this link</Typography>
          <Box sx={{ py: 2 }}>
            <Divider />
          </Box>
          <Box
            display="flex"
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mt: 3, gap: 10 }}
          >
            <Button disableElevation variant="contained" color="primary" onClick={() => redirect()}>Return</Button>
            <Button disableElevation variant="contained" color="inherit" onClick={handleLogout}>Logout</Button>
          </Box>
        </CustomPaper>
      </Grid>
    </Grid>
  );
};

AccessRestrictedError.defaultProps = {
  routePermission: [],
};

AccessRestrictedError.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  routePermission: PropTypes.array,
};

export default AccessRestrictedError;
