import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Link as MuiLink,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { supportPhone, supportEmail } from '../../constants/data';
import { useUser } from '../../contexts/UserContext';
import { XPAY_COMPANY_KB_LINK, XPAY_GC_KB_LINK, companyTypes } from '../../constants';

const useStyles = makeStyles((theme) => ({
  footer: {
    background: theme.palette.text.secondary,
    color: '#ccc',
    fontSize: '0.9em',
    flexShrink: 0,
    marginTop: 50,
    padding: '1em',
    '& .copyright': {
      textAlign: 'right',
    },
    '& .help': {
      textAlign: 'center',
      fontSize: '1.1em',
      '& a': {
        fontWeight: 600,
      },
    },
    '& hr': {
      borderWidth: 0.5,
      opacity: 0.2,
    },
    '& a': {
      textDecoration: 'none',
      color: '#ccc',
      '&:hover': {
        color: '#bbb',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .legal-links': {
        justifyContent: 'center',
      },
      '& .copyright': {
        textAlign: 'center',
      },
    },
  },
}));

export const Footer = () => {
  const classes = useStyles();
  const { user } = useUser();

  const isValidCompany = user.isEppCompany && (user.companyType === companyTypes.GENERAL_CONTRACTOR || companyTypes.SUB_CONTRACTOR);
  const KBUrl = user.companyType === companyTypes.GENERAL_CONTRACTOR ? XPAY_GC_KB_LINK : XPAY_COMPANY_KB_LINK;

  return (
    <footer className={classes.footer}>
      <Grid container spacing={2}>
        <Grid item className="help" xs={12}>
          <Box>
            Need help?
            {' '}
            {isValidCompany && <MuiLink href={KBUrl} target="_blank" color="inherit">Support Website</MuiLink>}
          </Box>
          <Box>
            Email:
            {' '}
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
          </Box>
          <Box>
            Call:
            {' '}
            <a href={`tel:${supportPhone}`}>{supportPhone}</a>
          </Box>
        </Grid>
        <Grid item xs={12}><hr /></Grid>
        <Grid item xs={12} sm={5}>
          <Box display="flex" className="legal-links">
            <Box mr={2}>
              <Link to="/legal/terms-of-use">Terms of Use</Link>
            </Box>
            { ' | ' }
            <Box ml={2}>
              <Link to="/legal/privacy-policy">Privacy Policy</Link>
            </Box>
          </Box>
        </Grid>
        <Grid item className="copyright" xs={12} sm={7}>
          <Box>
            {'Copyright '}
            &copy;
            {' '}
            { (new Date()).getFullYear() }
            {' '}
            Struxtion, LLC.
          </Box>
          <Box>
            All rights reserved.
          </Box>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
