import axios from 'axios';

export const updateDocumentSelector = (state) => state.updateDocument;

const UPDATE_DOCUMENT_RESET = 'UPDATE_DOCUMENT_RESET';
const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
const UPDATE_DOCUMENT_OK = 'UPDATE_DOCUMENT_OK';
const UPDATE_DOCUMENT_ERROR = 'UPDATE_DOCUMENT_ERROR';

export const updateDocumentReset = () => ({
  type: UPDATE_DOCUMENT_RESET,
});

export const updateDocumentRequest = () => ({
  type: UPDATE_DOCUMENT_REQUEST,
});

export const updateDocumentOk = (payload) => ({
  type: UPDATE_DOCUMENT_OK,
  payload,
});

export const updateDocumentError = (error) => ({
  type: UPDATE_DOCUMENT_ERROR,
  error,
});

export const updateDocumentThunk = (doc, onSuccess = () => {}) => (dispatch) => {
  dispatch(updateDocumentRequest());

  return axios.put(`/api/doc/${doc.id}`, { ...doc })
    .then(({ data }) => {
      dispatch(updateDocumentOk(data));
      onSuccess(data);
    })
    .catch(() => {
      dispatch(updateDocumentError('Failed to update document.'));
    });
};

export const initialState = {
  loading: false,
  loaded: false,
  document: {},
  error: '',
};

const updateDocument = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DOCUMENT_REQUEST:
      return { ...initialState, loading: true };
    case UPDATE_DOCUMENT_OK:
      return { ...initialState, loaded: true, document: action.payload };
    case UPDATE_DOCUMENT_ERROR:
      return { ...initialState, error: action.error };
    case UPDATE_DOCUMENT_RESET:
      return initialState;
    default:
      return state;
  }
};

export default updateDocument;
