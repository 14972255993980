import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { CheckIcon } from './icons/CircleIcons';

const useStyles = makeStyles((theme) => ({
  connector: {
    height: 3,
    width: '100%',
    marginTop: 32,
    backgroundColor: theme.palette.grey[500],
    [theme.breakpoints.down('sm')]: {
      marginTop: 25,
    },
  },
  connectorComplete: {
    backgroundColor: theme.palette.success.main,
  },
  iconMargins: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  iconRoot: {
    height: 65,
    width: 65,
    [theme.breakpoints.down('sm')]: {
      height: 50,
      width: 50,
    },
    [theme.breakpoints.down('sm')]: {
      height: 40,
      width: 40,
    },
  },
  active: {
    backgroundImage: theme.palette.gradients.primary,
  },
  inactive: {
    backgroundColor: theme.palette.text.secondary,
  },
  checkmark: {
    backgroundColor: theme.palette.success.main,
  },
  label: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '0.6rem',
    marginTop: theme.spacing(1),
  },
}));

export const Step = ({
  Icon, label, inactive, complete, hasConnector,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.iconMargins} display="flex" flexShrink={0} flexDirection="column" alignItems="center" textAlign="center" width={75}>
        { complete
          ? <CheckIcon className={`${classes.iconRoot} ${classes.checkmark}`} />
          : <Icon className={`${classes.iconRoot} ${inactive ? classes.inactive : classes.active}`} /> }
        <Typography className={classes.label}>{label}</Typography>
      </Box>
      { hasConnector
    && <Box className={`${classes.connector} ${complete && classes.connectorComplete}`} />}
    </>
  );
};

const Stepper = ({ steps, activeStep }) => (
  <Box display="flex">
    { steps.map((step, index) => (
      <Step
        {...step}
        key={step.label}
        inactive={activeStep !== index}
        complete={activeStep > index}
        hasConnector={index < steps.length - 1}
      />
    ))}
  </Box>
);

Step.propTypes = {
  Icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  inactive: PropTypes.bool.isRequired,
  complete: PropTypes.bool.isRequired,
  hasConnector: PropTypes.bool.isRequired,
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default Stepper;
