import React from 'react';
import axios from 'axios';

function useGetDocTypes() {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState('');
  const [docTypes, setDocTypes] = React.useState([]);

  const getDocTypes = React.useCallback(() => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setDocTypes([]);

    axios.get('/api/doc/type')
      .then(({ data }) => {
        setLoading(false);
        setDocTypes(data);
        setLoaded(true);
      })
      .catch(() => {
        setLoading(false);
        setError('Failed to get doc types.');
      });
  }, []);

  return {
    loading,
    loaded,
    error,
    docTypes,
    getDocTypes,
  };
}

export default useGetDocTypes;
