import axios from 'axios';
import { aboutCompanySelector } from './AboutCompany';
import { einLookupSelector } from './EINLookup';
import { companyTypes } from '../../constants';
import { setGtagCompany, setGtagUser } from '../../utils/analytics';

export const saveCompanyAndUserSelector = (state) => state.registration.saveCompanyAndUser;

const SAVE_COMPANY_REQUEST = 'SAVE_COMPANY_REQUEST';
const SAVE_COMPANY_OK = 'SAVE_COMPANY_OK';
const SAVE_COMPANY_ERROR = 'SAVE_COMPANY_ERROR';

export const saveCompanyRequest = () => ({
  type: SAVE_COMPANY_REQUEST,
});

export const saveCompanyOk = (payload) => ({
  type: SAVE_COMPANY_OK,
  payload,
});

export const saveCompanyError = (error) => ({
  type: SAVE_COMPANY_ERROR,
  error,
});

export const saveCompanyAndUserThunk = (user, setUser, keycloak) => async (dispatch, getState) => {
  dispatch(saveCompanyRequest());

  const { info } = aboutCompanySelector(getState());
  const { company } = einLookupSelector(getState());

  const companyData = {
    ...info,
    companyType: 'sub_contractor',
    contact: {
      id: user.contactId,
      email: user.email,
    },
  };

  try {
    let companyResponse = null;
    let userResponse = null;

    if (company.id) {
      userResponse = await axios.put(`/api/user/${user.id}`, { ...user, companyId: company.id });
      // refresh the keycloak token to get the company in it
      await keycloak.refresh();
      companyResponse = await axios.put(`/api/company/${company.id}`, { ...companyData });
    } else {
      if (companyData.id) {
        companyResponse = await axios.put(`/api/company/${companyData.id}`, { ...companyData });
      } else {
        companyResponse = await axios.post('/api/company', { ...companyData });
      }
      userResponse = await axios.put(`/api/user/${user.id}`, { ...user, companyId: companyResponse.data.id });
    }

    const { data: { registrationStatus } } = userResponse;
    const { data: { id, name, companyType } } = companyResponse;

    setUser({
      ...user,
      registrationStatus,
      companyId: id,
      companyName: name,
      companyType: companyTypes[companyType.toUpperCase()],
    });

    // refresh the keycloak token to get the company in it
    await keycloak.refresh();

    // analytics
    setGtagUser(user.id);
    setGtagCompany(id, name);

    dispatch(saveCompanyOk(companyResponse.data));
  } catch (e) {
    dispatch(saveCompanyError('Failed to save the company, please try again.'));
  }
};

export const initialState = {
  loading: false,
  loaded: false,
  error: null,
  company: null,
};

const company = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_COMPANY_REQUEST:
      return { ...initialState, loading: true };
    case SAVE_COMPANY_OK:
      return {
        ...initialState,
        loaded: true,
        company: action.payload,
      };
    case SAVE_COMPANY_ERROR:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};

export default company;
