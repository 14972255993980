import React from 'react';
import PropTypes from 'prop-types';
import useGetDocTypes from '../api/getDocTypes';

const GlobalsContext = React.createContext();

export const GlobalsProvider = ({ children, value }) => {
  const docTypes = useGetDocTypes();

  return (
    <GlobalsContext.Provider
      value={value || {
        docTypes,
      }}
    >
      {children}
    </GlobalsContext.Provider>
  );
};

export const useDocTypes = () => {
  const context = React.useContext(GlobalsContext);

  if (context === undefined) {
    throw new Error('useDocTypes must be used within a GlobalsProvider');
  }

  const { docTypes } = context;

  React.useEffect(() => {
    if (!docTypes.loading && !docTypes.loaded) {
      docTypes.getDocTypes();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return docTypes;
};

GlobalsProvider.defaultProps = {
  value: undefined,
};

GlobalsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    docTypes: PropTypes.object,
  }),
};
