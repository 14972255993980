/* eslint-disable import/prefer-default-export */
import {
  ApplicationIcon,
  BuildingIcon,
  CostIcon,
  DocumentIcon,
  PersonIcon,
  BusinessInfo,
  PersonInfo,
  TermsOfService,
  UserIcon,
  ProjectionsOnTransactions,
} from '../components/icons/CircleIcons';

export const preQualSteps = (isEppCompany) => [
  {
    Icon: PersonIcon,
    label: 'ABOUT YOU',
  },
  {
    Icon: BuildingIcon,
    label: 'ABOUT YOUR COMPANY',
  },
  {
    Icon: DocumentIcon,
    label: isEppCompany ? 'LINK YOUR ACCOUNT FOR PAYMENT' : 'ABOUT YOUR FINANCIALS',
  },
];

export const solidFiSteps = [
  {
    Icon: PersonInfo,
    label: 'PERSON INFORMATION',
  },
  {
    Icon: BusinessInfo,
    label: 'BUSINESS INFORMATION',
  },
  {
    Icon: ProjectionsOnTransactions,
    label: 'ANNUAL PROJECTIONS',
  },
  {
    Icon: UserIcon,
    label: 'OWNER INFORMATION',
  },
  {
    Icon: TermsOfService,
    label: 'ACCEPT TERMS OF SERVICE',
  },
];

export const loanApplication = [
  {
    Icon: DocumentIcon,
    label: 'APPLICATION',
  },
  {
    Icon: ApplicationIcon,
    label: 'REVIEW',
  },
  {
    Icon: PersonIcon,
    label: 'SIGNING',
  },
  {
    Icon: CostIcon,
    label: 'SERVICING',
  },
];
