import React from 'react';
import axios from 'axios';

function useUploadDocuments() {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState('');
  const [documents, setDocuments] = React.useState([]);

  const uploadDocuments = React.useCallback((docs, metaData, onSuccess = () => { }) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setDocuments([]);

    const formData = new FormData();

    docs.forEach((doc) => {
      formData.append('files', doc);
    });
    formData.append('fileInfo', JSON.stringify(metaData));

    axios.post('/api/doc/file', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        setLoading(false);
        setDocuments(data.docs);
        setLoaded(true);
        onSuccess(data.docs);
      })
      .catch((ex) => {
        let message = 'Failed to upload the selected documents.';

        if (ex?.response?.status === 413) {
          message += ' File(s) size exceeded 50MB.';
        }

        setLoading(false);
        setError(message);
      });
  }, []);
  const uploadChecks = React.useCallback((companyId, docs, metaData, onSuccess = () => { }) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setDocuments([]);

    const formData = new FormData();

    docs.forEach((doc) => {
      formData.append('files', doc);
    });
    formData.append('fileInfo', JSON.stringify(metaData));

    axios.post(`/api/struxtionBankAccount/${companyId}/receiveCheckDeposit`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        setLoading(false);
        setDocuments(data.docs);
        setLoaded(true);
        onSuccess(data.docs);
      })
      .catch((ex) => {
        let message = 'Failed to upload the selected documents.';

        if (ex?.response?.status === 413) {
          message += ' File size exceeded 20MB.';
        }

        setLoading(false);
        setError(message);
      });
  }, []);
  return {
    loading,
    loaded,
    error,
    documents,
    uploadDocuments,
    uploadChecks,
  };
}

export default useUploadDocuments;
