import React, { useCallback } from 'react';
import axios from 'axios';
import { companyTypes } from '../constants';

function useNotifications() {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState('');
  const [notifications, setNotifications] = React.useState([]);
  const [saved, setSaved] = React.useState(false);

  const getNotifications = useCallback(
    ({
      companyId,
      userId,
      companyType,
    }) => {
      setLoading(true);
      setLoaded(false);
      setError('');
      setNotifications([]);

      const globalNotifications = `/api/notifications/getAllByUserId?userId=${userId}`;
      const companyNotifications = `/api/notifications/getAllByCompanyId?companyId=${companyId}`;
      const url = companyType === companyTypes.GENERAL_CONTRACTOR ? globalNotifications : companyNotifications;

      axios
        .get(url)
        .then(({ data }) => {
          setLoading(false);
          setNotifications(data);
          setLoaded(true);
        })
        .catch(() => {
          setLoading(false);
          setError('Failed to retrieve Notifications.');
        });
    },
    [],
  );

  const updateStatusNotifications = (notificationsDTO) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setNotifications({});
    axios.put('/api/notifications/', notificationsDTO)
      .then(({ data }) => {
        setLoading(false);
        setNotifications(data);
        setLoaded(true);
      })
      .catch((e) => {
        setLoading(false);
        setError(e?.response?.message || 'Failed to update Notification.');
      });
  };

  const getUserCompanyCommunication = ({
    userId, companyId, templateId, roleId,
  }, onSuccess = () => {}) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    axios.post('/api/xpay/getUserCompanyCommunicationRoleAll/', {
      userId, companyId, templateId, roleId,
    })
      .then(({ data }) => {
        setLoading(false);
        onSuccess(data);
        setLoaded(true);
      })
      .catch((e) => {
        setLoading(false);
        setError(e?.response?.message || 'Failed to update Notification.');
      });
  };

  const updateUserCompanyCommunication = (body, onSuccess = () => {}) => {
    setLoading(true);
    setLoaded(false);
    setSaved(false);
    setError('');
    axios.put('/api/xpay/updateUserCompanyCommunicationRole/', body)
      .then(({ data }) => {
        setLoading(false);
        onSuccess(data);
        setLoaded(true);
        setSaved(true);
      })
      .catch((e) => {
        setLoading(false);
        setSaved(false);
        setError(e?.response?.message || 'Failed to update Notification.');
      });
  };

  return {
    error,
    getNotifications,
    updateStatusNotifications,
    notifications,
    loaded,
    loading,
    getUserCompanyCommunication,
    updateUserCompanyCommunication,
    saved,
  };
}
export default useNotifications;
