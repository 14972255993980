import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer as MUIDrawer,
  Hidden,
  List,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { drawerWidth } from '../../constants';
import DrawerLink from './DrawerLink';
import logo from '../../logo-x.svg';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: theme.shadows[0],
  },
  logoButton: {
    display: 'flex',
    width: '100%',
    minHeight: '38px',
    marginBottom: '1em',
  },
  logo: {
    width: '40px',
    height: 'auto',
    padding: '5px 0',
  },
}));

export const DrawerLinks = ({ links, onLinkClick }) => (
  <List>
    {
      links.map(({
        label,
        icon,
        to,
        index,
        rolePermission,
      }) => (
        <DrawerLink
          rolePermission={rolePermission}
          key={label}
          label={label}
          icon={icon}
          to={to}
          onClick={onLinkClick}
          index={index}
        />
      ))
    }
  </List>
);

const Drawer = ({
  toggleDrawer,
  drawerOpen,
  links,
  defaultPath,
  user: {
    companyName,
  },
}) => {
  const classes = useStyles();

  return (
    <nav className={classes.drawer}>
      <Hidden mdUp implementation="css">
        <MUIDrawer
          variant="temporary"
          open={drawerOpen}
          onClose={toggleDrawer}
          classes={{ paper: classes.drawerPaper }}
        >
          <Box textAlign="center">
            <h4>{companyName?.toUpperCase()}</h4>
          </Box>
          <DrawerLinks links={links} onLinkClick={toggleDrawer} />
        </MUIDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <MUIDrawer
          variant="permanent"
          open
          classes={{ paper: classes.drawerPaper }}
        >
          <Link to={defaultPath}>
            <Button className={classes.logoButton}>
              <img className={classes.logo} src={logo} alt="Struxtion logo, navigate to home" />
            </Button>
          </Link>
          <DrawerLinks links={links} />
        </MUIDrawer>
      </Hidden>
    </nav>
  );
};

DrawerLinks.defaultProps = {
  links: [],
  onLinkClick: () => { },
};

DrawerLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    icon: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
    to: PropTypes.string,
  })),
  onLinkClick: PropTypes.func,
};

Drawer.defaultProps = {
  links: [],
};

Drawer.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    icon: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
    to: PropTypes.string,
  })),
  defaultPath: PropTypes.string.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
};

export default Drawer;
