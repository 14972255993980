import axios from 'axios';
import { triggerGtagValidationEvent } from '../../utils/analytics';

export const einLookupSelector = (state) => state.registration.einLookup;

const EIN_LOOKUP_REQUEST = 'EIN_LOOKUP_REQUEST';
const EIN_LOOKUP_OK = 'EIN_LOOKUP_OK';
const EIN_LOOKUP_ERROR = 'EIN_LOOKUP_ERROR';

export const einLookupRequest = () => ({
  type: EIN_LOOKUP_REQUEST,
});

export const einLookupOk = (payload) => ({
  type: EIN_LOOKUP_OK,
  payload,
});

export const einLookupError = (error) => ({
  type: EIN_LOOKUP_ERROR,
  error,
});

export const einLookupThunk = (ein) => (dispatch) => {
  dispatch(einLookupRequest());

  return axios.get(`/api/company/ein/${ein}`)
    .then(({ data }) => {
      if (data.length > 0) {
        dispatch(einLookupOk(data[0]));
        triggerGtagValidationEvent('EIN Exists');
      } else {
        dispatch(einLookupOk({}));
      }
    })
    .catch(() => { dispatch(einLookupError('EIN lookup failed, please try again.')); });
};

export const initialState = {
  loading: false,
  loaded: false,
  error: null,
  company: {},
};

const einLookup = (state = initialState, action) => {
  switch (action.type) {
    case EIN_LOOKUP_REQUEST:
      return { ...initialState, loading: true };
    case EIN_LOOKUP_OK:
      return {
        ...initialState,
        loaded: true,
        company: action.payload,
      };
    case EIN_LOOKUP_ERROR:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};

export default einLookup;
