import React, { useCallback } from 'react';
import axios from 'axios';

function useTraceability() {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState('');
  const [traceability, setTraceability] = React.useState(false);

  const updateTraceability = useCallback(
    ({
      companyId, status,
    }) => {
      setLoading(true);
      setLoaded(false);
      setError('');
      setTraceability(false);

      let params = '';

      if (status) params += `status=${status}`;

      axios
        .post(`/api/xpay/sc/${companyId}/updateScRelationToGc?${params}`)
        .then(({ data }) => {
          setLoading(false);
          setTraceability(data);
          setLoaded(true);
        })
        .catch(() => {
          setLoading(false);
          setError('Failed to retrieve traceability.');
        });
    },
    [],
  );

  return {
    traceability,
    error,
    updateTraceability,
    loaded,
    loading,
  };
}
export default useTraceability;
