import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CustomButton = ({
  loading, success, children, disabled, ...props
}) => {
  const classes = useStyles();

  const [successEvent, setSuccessEvent] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (success) {
      setSuccessEvent(true);
      const timeout = setTimeout(() => {
        setSuccessEvent(false);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [success]);

  return (
    <div className={classes.wrapper}>
      <Button
        {...props}
        className={successEvent ? classes.buttonSuccess : null}
        disabled={disabled || loading}
        startIcon={successEvent ? <DoneIcon /> : null}
      >
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

CustomButton.defaultProps = {
  disabled: false,
  success: false,
};

CustomButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default CustomButton;
