import React from 'react';
import axios from 'axios';

function useGetDocuments() {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState('');
  const [documents, setDocuments] = React.useState([]);

  const getDocuments = React.useCallback((companyId, { docType, excludeDocType } = {}) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setDocuments([]);

    const queryParams = [];
    queryParams.push(`companyId=${companyId}`);
    if (docType) queryParams.push(`docType=${docType}`);
    if (excludeDocType) queryParams.push(`excludeDocType=${excludeDocType}`);

    axios.get(`/api/doc?${queryParams.join('&')}`)
      .then(({ data }) => {
        setLoading(false);
        setDocuments(data.docs);
        setLoaded(true);
      })
      .catch(() => {
        setLoading(false);
        setError('Failed to retrieve documents.');
      });
  }, []);

  const getDocumentsXpay = React.useCallback(({
    companyId, docType, relatedCompanyId, projectId, startDate, endDate,
  }) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setDocuments([]);

    let paramString = '';
    if (docType) paramString += `docType=${docType}&`;
    if (relatedCompanyId) paramString += `relatedCompanyId=${relatedCompanyId}&`;
    if (projectId) paramString += `projectId=${projectId}&`;
    if (startDate) paramString += `startDate=${startDate}&`;
    if (endDate) paramString += `endDate=${endDate}&`;

    axios.get(`/api/xpay/${companyId}/getDocByGc?${paramString}`)
      .then(({ data }) => {
        setLoading(false);
        setDocuments(data);
        setLoaded(true);
      })
      .catch(() => {
        setLoading(false);
        setError('Failed to retrieve documents.');
      });
  }, []);

  const getDocumentsXpayBySc = React.useCallback(({
    companyId, docType, relatedCompanyId, projectId, startDate, endDate,
  }) => {
    setLoading(true);
    setLoaded(false);
    setError('');
    setDocuments([]);

    let paramString = '';
    if (docType) paramString += `docType=${docType}&`;
    if (relatedCompanyId) paramString += `relatedCompanyId=${relatedCompanyId}&`;
    if (projectId) paramString += `projectId=${projectId}&`;
    if (startDate) paramString += `startDate=${startDate}&`;
    if (endDate) paramString += `endDate=${endDate}&`;

    axios.get(`/api/xpay/${companyId}/getDocBySc?${paramString}`)
      .then(({ data }) => {
        setLoading(false);
        setDocuments(data);
        setLoaded(true);
      })
      .catch(() => {
        setLoading(false);
        setError('Failed to retrieve documents.');
      });
  }, []);

  return {
    loading,
    loaded,
    error,
    documents,
    getDocuments,
    getDocumentsXpay,
    getDocumentsXpayBySc,
  };
}

export default useGetDocuments;
