import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Box,
  List,
  ListItem,
  ListSubheader,
  ListItemSecondaryAction,
  Switch,
  ListItemText,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Typography,
  Dialog,
  FormControl,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useUser } from '../../contexts/UserContext';
import { availableRolesSelect } from '../Roles/RolesList';
import useNotifications from '../../api/useNotifications';
import CustomButton from '../MaterialUI/CustomButton';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '500px',
    maxWidth: '100%',
  },
  dialogContent: {
    padding: 0,
  },
  listHeader: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const EmailNotifications = ({ open, setModal }) => {
  const classes = useStyles();
  const { user } = useUser();

  const [emailNotifications, setEmailNotifications] = useState([]);
  const [selectedRole, setSelectedRole] = useState(
    availableRolesSelect(user.struxtionRoles, user.companyId)[0].value,
  );

  const {
    loading, loaded, error, getUserCompanyCommunication, updateUserCompanyCommunication, saved,
  } = useNotifications();

  useEffect(() => {
    getUserCompanyCommunication(
      {
        userId: user.id,
        companyId: user.companyId,
        roleId: selectedRole,
        templateId: null,
      },
      (dataLoaded) => {
        setEmailNotifications(dataLoaded.sort((a, b) => a.description?.localeCompare(b.description)));
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);

  const handleClose = () => {
    setModal(false);
  };

  const handleChange = (event, id) => {
    const newEmailNotificationsArray = emailNotifications.map((item) => {
      if (item.id === id) {
        return { ...item, active: event.target.checked };
      }
      return item;
    });
    setEmailNotifications(newEmailNotificationsArray);
  };

  const onSubmit = () => {
    updateUserCompanyCommunication(emailNotifications);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="offered-dialog-title"
    >
      <DialogTitle id="offered-dialog-title">Manage my messages</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <List
          subheader={(
            <ListSubheader className={classes.listHeader}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Typography variant="body1">{`Company: ${user.companyName}`}</Typography>
                </Box>
                <Box>
                  <FormControl
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Roles
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      defaultValue={selectedRole}
                      onChange={(e) => setSelectedRole(e.target.value)}
                      label="Roles"
                    >
                      {
                        availableRolesSelect(user.struxtionRoles, user.companyId).map(
                          (roles) => (
                            <MenuItem key={roles.value} value={roles.value}>{roles.name}</MenuItem>
                          ),
                        )
                      }
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </ListSubheader>
          )}
          className={classes.root}
        >
          {
            loading && [...new Array(5).keys()].map((item) => (
              <ListItem key={item}>
                <ListItemText primary={<Typography variant="subtitle2"><Skeleton variant="text" /></Typography>} />
              </ListItem>
            ))
          }
          {loaded && emailNotifications.length ? emailNotifications.map((notification) => (
            <ListItem key={notification.id}>
              <ListItemText id="switch-list-label-notification" primary={<Typography variant="subtitle2">{notification?.description}</Typography>} />
              <ListItemSecondaryAction>
                <Switch
                  color="primary"
                  checked={notification?.active}
                  disabled={!notification?.optional}
                  edge="end"
                  onChange={(e) => handleChange(e, notification.id)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          )) : null}
          {
            loaded && !emailNotifications.length
              ? (
                <ListItem>
                  <ListItemText primary={<Typography variant="subtitle2">No data available</Typography>} />
                </ListItem>
              ) : null
          }
        </List>
      </DialogContent>
      <DialogActions>
        {error && <Alert severity="warning">{error}</Alert>}
        <CustomButton
          disableElevation
          onClick={onSubmit}
          variant="contained"
          disabled={loading || !emailNotifications.length}
          color="primary"
          loading={loading}
          success={saved}
        >
          Save
        </CustomButton>
        <Button
          disableElevation
          onClick={handleClose}
          variant="contained"
          color="inherit"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EmailNotifications.propTypes = {
  open: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
};

export default EmailNotifications;
