export const aboutCompanySelector = (state) => state.registration.aboutCompany;

const SET_ABOUT_COMPANY_VALUES = 'SET_ABOUT_COMPANY_VALUES';

export const setAboutCompanyValues = (payload) => ({
  type: SET_ABOUT_COMPANY_VALUES,
  payload,
});

export const initialState = {
  info: {},
  address: {},
  loans: {},
};

const aboutCompany = (state = initialState, action) => {
  switch (action.type) {
    case SET_ABOUT_COMPANY_VALUES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default aboutCompany;
