import axios from 'axios';

const STRUXTION_EXCEPTION = 'Struxtion Exception';
const CONSTRAINT_VIOLATION = 'Constraint Violation';

const visibleReasons = [STRUXTION_EXCEPTION, CONSTRAINT_VIOLATION];

axios.interceptors.response.use(
  async (res) => (res),
  (error) => {
    if (visibleReasons.includes(error?.response?.data?.error)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        response: {
          ...error.response,
          message: error.response.data.message,
        },
      });
    }

    return Promise.reject(error);
  },
);
