import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Checkmark from '@material-ui/icons/Check';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import application from '../../assets/icons/application-white.svg';
import building from '../../assets/icons/bldg-white.svg';
import cost from '../../assets/icons/cost-white.svg';
import document from '../../assets/icons/document-white.svg';
import person from '../../assets/icons/person-white.svg';
import upfrontPricing from '../../assets/icons/upfront-pricing-white.svg';
import user from '../../assets/icons/user-white.svg';

const defaultStyling = {
  backgroundColor: '#9e9e9e',
  width: 65,
  height: 65,
};

const useStyles = makeStyles({
  root: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    position: 'relative',
  },
});

export const IconBackground = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${className}`} style={className ? {} : defaultStyling}>
      {React.cloneElement(children, { className: classes.icon })}
    </div>
  );
};

export const ApplicationIcon = (props) => (
  <IconBackground {...props}>
    <img src={application} alt="application icon" style={{ width: '45%', top: -2, left: 1 }} />
  </IconBackground>
);

export const BuildingIcon = (props) => (
  <IconBackground {...props}>
    <img src={building} alt="building icon" style={{ width: '65%', top: -2, left: 1 }} />
  </IconBackground>
);

export const BusinessInfo = (props) => (
  <IconBackground {...props}>
    <img src={building} alt="bussiness info" style={{ width: '65%', top: -2, left: 1 }} />
  </IconBackground>
);

export const DocumentIcon = (props) => (
  <IconBackground {...props}>
    <img src={document} alt="document icon" style={{ width: '40%', left: 1 }} />
  </IconBackground>
);

export const CostIcon = (props) => (
  <IconBackground {...props}>
    <img src={cost} alt="cost icon" style={{ width: '45%', left: 1 }} />
  </IconBackground>
);

export const PersonIcon = (props) => (
  <IconBackground {...props}>
    <img src={person} alt="person icon" style={{ width: '75%', top: -5 }} />
  </IconBackground>
);

export const PersonInfo = (props) => (
  <IconBackground {...props}>
    <img src={person} alt="person info" style={{ width: '70%', top: -5 }} />
  </IconBackground>
);

export const UpfrontPricingIcon = (props) => (
  <IconBackground {...props}>
    <img src={upfrontPricing} alt="person icon" style={{ width: '65%', top: -3 }} />
  </IconBackground>
);
export const UserIcon = (props) => (
  <IconBackground {...props}>
    <img src={user} alt="user icon" style={{ width: '65%', top: -2 }} />
  </IconBackground>
);
export const CheckIcon = (props) => (
  <IconBackground {...props}>
    <Checkmark style={{ width: '75%', height: '75%', color: 'white' }} />
  </IconBackground>
);

export const ErrorIcon = (props) => (
  <IconBackground {...props}>
    <PriorityHigh style={{ width: '60%', height: '60%', color: 'white' }} />
  </IconBackground>
);

export const ProjectionsOnTransactions = (props) => (
  <IconBackground {...props}>
    <AccountBalanceWalletIcon style={{ width: '60%', height: '60%', color: 'white' }} />
  </IconBackground>
);

export const TermsOfService = (props) => (
  <IconBackground {...props}>
    <img src={document} alt="terms of service" style={{ width: '45%', left: 1 }} />
  </IconBackground>
);

IconBackground.defaultProps = {
  className: undefined,
};

IconBackground.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
