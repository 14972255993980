// Custom struxtion theme

export const palette = {
  background: {
    default: '#E5ECF2',
    shadow: '#F4F4F4',
  },
  text: {
    primary: '#232C38',
    secondary: '#43505B',
    primaryFaded: '#98a7b3',
    captionBlue: '#1B75BA',
  },
  primary: {
    main: '#29B473',
    dark: '#417955',
  },
  secondary: {
    main: '#298FDE',
    dark: '#32597A',
  },
  success: {
    main: '#53B275',
  },
  error: {
    main: '#f44336',
  },
  warning: {
    main: '#E99C20',
  },
  info: {
    main: '#3B76B9',
  },
  gradients: {
    primary: 'linear-gradient(147.09deg, #29B473 13.68%, #3B76B9 86.89%);',
    horizontal: 'linear-gradient(90deg, #29B473 0%, #3B76B9 100%);',
  },
  midGray: '#CCCCCC',
  primaryGreen: '#29B473',
  secondaryBlue: '#298FDE',
  captionBlue: '#1B75BA',
  warningRed: '#E99C20',
  darkGray: '#777777',
};

export const typography = {
  h1: {
    fontSize: '1.875rem',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  h2: {
    fontSize: '1.625rem',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
  },
  h3: {
    fontSize: '1.5rem',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
  },
  h4: {
    fontSize: '1.375rem',
  },
  h5: {
    fontSize: '1.313rem',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  h6: {
    fontSize: '1.2rem',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  body1: {
    fontSize: '1.1rem',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  body2: {
    fontWeight: 500,
    fontSize: '0.9rem',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  overline: {
    fontSize: '0.7rem',
    color: '#808185',
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  subtitle1: {
    fontSize: '1.2rem',
    fontWeight: '500',
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  caption: {
    fontSize: '0.85rem',
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: 0.1,
    display: 'block',
    color: '#1B75BA',
    marginBottom: '8px',
  },
  fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
};

export const overrides = {
  MuiAlert: {
    standardSuccess: {
      backgroundColor: 'white',
      borderLeft: `8px solid ${palette.success.main}`,
      borderRadius: '8px',
      boxShadow: '0px 0px 25px 0px rgba(0, 0, 0, 0.05)',
    },
    standardError: {
      backgroundColor: 'white',
      borderLeft: `8px solid ${palette.error.main}`,
      borderRadius: '8px',
      boxShadow: '0px 0px 25px 0px rgba(0, 0, 0, 0.05)',
    },
    standardWarning: {
      backgroundColor: 'white',
      borderLeft: `8px solid ${palette.warning.main}`,
      borderRadius: '8px',
      boxShadow: '0px 0px 25px 0px rgba(0, 0, 0, 0.05)',
    },
    standardInfo: {
      backgroundColor: 'white',
      borderLeft: `8px solid ${palette.info.main}`,
      borderRadius: '8px',
      boxShadow: '0px 0px 25px 0px rgba(0, 0, 0, 0.05)',
    },
    icon: {
      display: 'none',
    },
    message: {
      width: '100%',
      wordWrap: 'break-word',
    },
  },
  MuiButton: {
    root: {
      borderRadius: '8px',
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      textTransform: 'uppercase',
    },
    containedPrimary: {
      color: 'white',
    },
  },
  MuiChip: {
    colorPrimary: {
      color: '#fff',
    },
    deleteIconColorPrimary: {
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover': {
        color: '#fff',
      },
    },
  },
  MuiInputLabel: {
    root: {
      color: palette.secondary.main,
      '&$focused': {
        color: palette.secondary.main,
      },
    },
  },
  // MuiInput: {
  //   underline: {
  //     '&::after': {
  //       border: '1px solid #9e9e9e',
  //     },
  //     '&:hover:not(.Mui-disabled):before': {
  //       borderBottom: '1px solid #9e9e9e',
  //     },
  //   },
  // },
  MuiFormControl: {
    root: {
      marginBottom: 16,
    },
  },
  MuiPaper: {
    elevation24: {
      boxShadow: '0px 4px 46px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
    },
  },
  MuiTableCell: {
    head: {
      color: '#76797B',
      fontSize: '0.95em',
    },
  },
};

export default {
  palette,
  typography,
  overrides,
};
