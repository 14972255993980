/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Stepper from '../components/Stepper';
import { preQualSteps } from '../constants/steppers';
import { AppBarWithoutDrawer } from './AppBarDrawer';
import { useUser } from '../contexts/UserContext';
import useTraceability from '../api/useTraceability';

const Registration = ({ children }) => {
  const { pathname } = useLocation();
  const { user } = useUser();
  const step = parseInt(pathname.substring(pathname.length - 1, pathname.length), 10);
  const {
    updateTraceability,
  } = useTraceability();

  React.useEffect(() => {
    if (user?.isEppCompany) updateTraceability({ companyId: user?.companyId, status: user?.registrationStatus });
  }, [updateTraceability, user?.companyId, user?.isEppCompany, user?.registrationStatus]);

  return (
    <AppBarWithoutDrawer hideLogo={false}>
      <Box marginX="auto" marginTop={2} marginBottom={6} width="100%" maxWidth={600}>
        <Stepper steps={preQualSteps(user?.isEppCompany)} activeStep={isNaN(step) ? 0 : (step - 1)} />
      </Box>
      <Box display="flex" justifyContent="center">
        {children}
      </Box>
    </AppBarWithoutDrawer>
  );
};

Registration.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Registration;
