import axios from 'axios';

export const documentsSelector = (state) => state.documents;

const GET_DOCUMENTS_RESET = 'GET_DOCUMENTS_RESET';
const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST';
const GET_DOCUMENTS_OK = 'GET_DOCUMENTS_OK';
const GET_DOCUMENTS_ERROR = 'GET_DOCUMENTS_ERROR';

export const getDocumentsReset = () => ({
  type: GET_DOCUMENTS_RESET,
});

export const getDocumentsRequest = () => ({
  type: GET_DOCUMENTS_REQUEST,
});

export const getDocumentsOk = (payload) => ({
  type: GET_DOCUMENTS_OK,
  payload,
});

export const getDocumentsError = (error) => ({
  type: GET_DOCUMENTS_ERROR,
  error,
});

export const getDocumentsResetThunk = () => (dispatch) => {
  dispatch(getDocumentsReset());
};

export const getDocumentsThunk = (companyId, params = {}, onSuccess = () => {}) => (dispatch) => {
  dispatch(getDocumentsRequest());

  const queryParams = [];
  queryParams.push(`companyId=${companyId}`);
  Object.keys(params).forEach((p) => {
    queryParams.push(`${p}=${params[p]}`);
  });

  return axios.get(`/api/doc?${queryParams.join('&')}`)
    .then(({ data }) => {
      dispatch(getDocumentsOk(data));
      onSuccess();
    })
    .catch(() => {
      dispatch(getDocumentsError('Failed to retrieve documents.'));
    });
};

export const downloadDocumentThunk = (companyId, doc) => () => {
  const { id, docName } = doc;

  return axios({
    url: `/api/doc/file/${id}?companyId=${companyId}`,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', docName);
      document.body.appendChild(link);
      link.click();
    });
};

export const initialState = {
  loading: false,
  loaded: false,
  documents: [],
  error: '',
};

const documents = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_REQUEST:
      return { ...initialState, loading: true };
    case GET_DOCUMENTS_OK:
      return { ...initialState, loaded: true, documents: action.payload?.docs || [] };
    case GET_DOCUMENTS_ERROR:
      return { ...initialState, error: action.error };
    case GET_DOCUMENTS_RESET:
      return initialState;
    default:
      return state;
  }
};

export default documents;
