import axios from 'axios';

export const statementsSelector = (state) => state.statements;

const GET_STATEMENTS_REQUEST = 'GET_STATEMENTS_REQUEST';
const GET_STATEMENTS_OK = 'GET_STATEMENTS_OK';
const GET_STATEMENTS_ERROR = 'GET_STATEMENTS_ERROR';

export const getStatementsRequest = () => ({
  type: GET_STATEMENTS_REQUEST,
});

export const getStatementsOk = (payload) => ({
  type: GET_STATEMENTS_OK,
  payload,
});

export const getStatementsError = (error) => ({
  type: GET_STATEMENTS_ERROR,
  error,
});

export const getStatementsThunk = (companyId) => (dispatch) => {
  dispatch(getStatementsRequest());

  return axios.get(`/api/statement?companyId=${companyId}`)
    .then(({ data }) => {
      dispatch(getStatementsOk(data));
    })
    .catch(() => {
      dispatch(getStatementsError('Failed to retrieve statements.'));
    });
};

export const initialState = {
  loading: false,
  loaded: false,
  statements: [],
  error: '',
};

const statements = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATEMENTS_REQUEST:
      return { ...initialState, loading: true };
    case GET_STATEMENTS_OK:
      return { ...initialState, loaded: true, statements: action.payload.statements };
    case GET_STATEMENTS_ERROR:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};

export default statements;
