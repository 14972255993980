import ApproverIcon from '../../assets/icons/approver.svg';
import FinalApproverIcon from '../../assets/icons/final-approver.svg';
import AdminIcon from '../../assets/icons/admin.svg';
import ExternalApproverIcon from '../../assets/icons/external-approver.svg';
// import ViewerIcon from '../../assets/icons/viewer.svg';
import SuperAdminIcon from '../../assets/icons/super-admin.svg';
import PaymentProcessorIcon from '../../assets/icons/payment-processor.svg';
import { LienWaiverAssigner, circleIcon } from '../../assets/icons';

export const availableRoles = [
  {
    key: 'superAdmin',
    name: 'Super Admin',
    value: '5',
    icon: SuperAdminIcon,
  },
  {
    key: 'admin',
    name: 'Admin',
    value: '6',
    icon: AdminIcon,
  },
  {
    key: 'paymentProcessor',
    name: 'Payment Processor',
    value: '7',
    icon: PaymentProcessorIcon,
  },
  {
    key: 'finalApprover',
    name: 'Final Approver',
    value: '9',
    icon: FinalApproverIcon,
  },
  {
    key: 'approver',
    name: 'Approver',
    value: '8',
    icon: ApproverIcon,
  },
  /* {
    key: 'observer',
    name: 'Observer',
    value: '11',
    icon: ViewerIcon,
  }, */
  {
    key: 'externalApprover',
    name: 'External Approver',
    value: '10',
    icon: ExternalApproverIcon,
  },
  {
    key: 'projectAccountant',
    name: 'Project Accountant',
    value: '12',
    icon: ExternalApproverIcon,
  },
];

export const supplierRoles = [
  {
    key: 'main_contact',
    name: 'Main Contact',
    value: '11',
    icon: circleIcon,
  },
  {
    key: 'lien_waiver_signer',
    name: 'Lien Waiver Signer',
    value: '12',
    icon: LienWaiverAssigner,
  },
];

export const availableRolesSelect = (roles, companyId) => {
  const available = roles.map((rol) => {
    const rolObject = {};
    const selectedRolValue = availableRoles.find((e) => e.value === rol.id.toString() && rol.companyId === companyId);
    if (selectedRolValue) {
      rolObject.value = parseInt(selectedRolValue.value, 10);
      rolObject.name = selectedRolValue.name;
    }
    return rolObject;
  });
  return available.filter((value) => Object.keys(value).length !== 0);
};

export const rolesList = [];
