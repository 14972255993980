import { PropTypes } from 'prop-types';
import { useUser } from '../contexts/UserContext';
import { hasActionPermission } from '../utils';
import { companyTypes } from '../constants';

const HiddenByPermission = ({ children, permission }) => {
  const { user } = useUser();
  const showItem = user?.companyType === companyTypes.GENERAL_CONTRACTOR
    && user.isEppCompany
    && hasActionPermission(permission, user.actionsByRoles, user?.companyType);

  // PERMISSION ONLY APPLY BY XPAY GC
  if (user?.companyType === companyTypes.SUB_CONTRACTOR || user?.companyType === companyTypes.STRUXTION) return children;
  return showItem ? children : null;
};

HiddenByPermission.defaultProps = {
  children: null,
};

HiddenByPermission.propTypes = {
  permission: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
};

export default HiddenByPermission;
