/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from '@material-ui/core';

export const Confirmation = () => {
  const programType = 'sl';

  const headcells = [
    { id: 'benefits', color: 'white', label: 'Benefits' },
    { id: 'struxtion', label: 'Struxtion', color: '#53B275' },
    { id: 'otherGuys', label: 'Other Guys', color: '#298FDE' },
  ];
  function createData(benefit, struxtion, others) {
    return { benefit, struxtion, others };
  }
  const rows = [
    createData('ENROLLMENT FEES', 'NO', 'YES'),
    createData('CLOSING FEES', 'NO', 'YES'),
    createData('FUNDING FEE', '0.5%', '2%+'),
    createData('WEEKLY PAYMENTS', 'NO', 'YES'),
    createData('MONEY IN 3 DAYS OR LESS?', 'YES', 'NO'),
  ];

  return (
    <Box width="100%" maxWidth={700} justifyContent="center">
      <Paper className="content-block" square style={{ padding: '3em', fontSize: '1.4em' }}>
        <Box mb={3}>
          <Typography variant="h3" align="center">Congratulations...Please wait</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="body2">{programType === 'epp' ? 'You are one step close to getting your invoices paid with the Struxtion Early Pay Program.' : 'You have completed all the needed steps to get pre-approved with Struxtion. One of our specialists will contact you with the exact amount pre-approved and next steps.'}</Typography>
        </Box>
        <Paper>
          <Box mt={3}>
            <Typography variant="h4" align="center">How you win with Struxtion?</Typography>
          </Box>
          <Box display="flex" justifyContent="center" mt={3}>
            <TableContainer component={Paper}>
              <Table aria-label="Benefits table">
                <TableHead>
                  <TableRow>
                    {headcells.map((cell) => (
                      <TableCell component="th" id={cell.id}>
                        <Typography variant="body" style={{ color: cell.color }}>{cell.label || ' '}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell align="left"><Typography variant="body">{row.benefit}</Typography></TableCell>
                      <TableCell align="left"><Typography variant="body">{row.struxtion}</Typography></TableCell>
                      <TableCell align="left"><Typography variant="body">{row.others}</Typography></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Paper>
    </Box>
  );
};

Confirmation.propTypes = {};

export default connect()(Confirmation);
